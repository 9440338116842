import {Col, Container, Row, Form, Button, Image} from "react-bootstrap";
import TopNavBar from "./TopNavBar";
import {useEffect, useState} from "react";
import {ComponentPropsToStylePropsMapKeys, useAuthenticator} from "@aws-amplify/ui-react";
import { generateClient } from 'aws-amplify/api';
import {pickByComboID, userByEmail, pickByUserEvent, pickByEvent} from "../graphql/queries";
import {createPick, createPickUser, createUser, updatePick, customCreateUser} from "../graphql/mutations";
import {listEventOptionsForYear, listPlayersForEvent, getPicksByEventID, getUserPicksByEventID, getPicksByUserID} from "../customGraphql/queries";
import PlayerPicker from "./PlayerPicker";
import { ToastContainer, toast, Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAuthSession } from 'aws-amplify/auth';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import { TailSpin } from "react-loader-spinner";

const client = generateClient();

function Home(props) {
  const { user } = useAuthenticator((context) => [context.user]);
  const [eventOptions, setEventOptions] = useState();
  const [mpoPlayerOptions, setMpoPlayerOptions] = useState();
  const [mpoPopDropPlayerOptions, setMpoPopDropPlayerOptions] = useState();
  const [fpoPlayerOptions, setFpoPlayerOptions] = useState();
  const [fpoPopDropPlayerOptions, setFpoPopDropPlayerOptions] = useState();
  const [dynoUser, setDynoUser] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [userPick, setUserPick] = useState();
  const [mpoPopPick, setMpoPopPick] = useState(-1)
  const [mpoLockPick, setMpoLockPick] = useState(-1)
  const [mpoDropPick, setMpoDropPick] = useState(-1)
  const [fpoPopPick, setFpoPopPick] = useState(-1)
  const [fpoLockPick, setFpoLockPick] = useState(-1)
  const [fpoDropPick, setFpoDropPick] = useState(-1)
  const [username, setUsername] = useState();
  const [theEvent, setTheEvent] = useState(-1);
  const [picksForEvent, setPicksForEvent] = useState();
  const [userScore, setUserScore] = useState();
  const [loading, setLoading] = useState(false);
  const [useRating, setUseRating] = useState(false);
  const [savedUserPicks, setSavedUserPicks] = useState();
  const [selectedYear, setSelectedYear] = useState(2025);  

  const handleYearChange = (year) => {
    setSelectedYear(year);
    getEvents(year)
  };
  
  function getEvents(year) {
    // Compute the start and end dates for the provided year
    const startOfYear = new Date(Date.UTC(year, 0, 1, 0, 0, 0)).toISOString();  // January 1st, 00:00 UTC
    const endOfYear = new Date(Date.UTC(year, 11, 31, 23, 59, 59)).toISOString();  // December 31st, 23:59 UTC

    const variables = { startOfYear, endOfYear };  // Pass year and date range as variables


    client.graphql({
      query: listEventOptionsForYear,
      variables: variables
    }).then(response => {
      let events = response.data.listEvents.items 
      events.sort((a, b) => {
        return new Date(a.startDate) - new Date(b.startDate);
      })
      setEventOptions(response.data.listEvents.items);
    }).catch(error => {
      console.log(error)
    })
  }

  async function main() {
    handleYearChange(2025)

    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    let email = idToken.payload.email
  
    if (email === undefined) {
      console.log("unable to determine user email")
      return;
    }
  
    client.graphql({
      query: userByEmail,
      variables: {
        email: email
      }
    }).then(response => {
      // Add user to User table if it doesn't exist
      if (response.data.userByEmail.items.length === 0) {
        client.graphql({
          query: customCreateUser
        }).then(newUser => {
          let jsonData = JSON.parse(newUser.data.customCreateUser)
          let user = jsonData.body.data.createUser
          setDynoUser(user);
          setUsername(user.username);
        })
      } else {
        let user = response.data.userByEmail.items[0]
        setDynoUser(user);
        setUsername(user.username);
      }
    }).catch(error => {
      console.log(error)
    })
  }
  

  // Check for user in User table and add if it doesn't exist
  useEffect(() => {
    main();
  }, []);

  useEffect(() => {
    if (dynoUser && eventOptions && eventOptions.length > 0) {
      // Get current timestamp, subtract 12 hours so we can see results
      const now = new Date().getTime() - 12 * 60 * 60 * 1000;
  
      // Find the next upcoming event
      const nextEvent = eventOptions
        .filter(event => new Date(event.endDate).getTime() > now) // Only future events
        .sort((a, b) => new Date(a.endDate) - new Date(b.endDate)) // Sort by start time
        [0]; // Get the first one
  
      // Set the next event as selected
      if (nextEvent) {
        eventSelected(nextEvent.id)
      }
    }
  }, [eventOptions, dynoUser]);

  function handlePickChange(event) {
    switch (event.target.name) {
      case "mpoPopId":
        setMpoPopPick(event.target.value)
        break;
      case "mpoLockId":
        setMpoLockPick(event.target.value)
        break;  
      case "mpoDropId":
        setMpoDropPick(event.target.value)
        break;
      case "fpoPopId":
        setFpoPopPick(event.target.value)
        break;
      case "fpoLockId":
        setFpoLockPick(event.target.value)
        break;  
      case "fpoDropId":
        setFpoDropPick(event.target.value)
        break;
    }
  }

  const handleSubmit = async (event) => {
    setSubmitDisabled(true)
    event.preventDefault();

    const form = event.currentTarget;
    const formData = new FormData(form);

    let formJson = Object.fromEntries(formData.entries());
    formJson.userId = dynoUser.id;

    if (formJson.eventId === "-1") {
      setSubmitDisabled(false)
      return;
    }

    // setup userEventId composite key
    formJson["userEventId"]= formJson.userId + "." + formJson.eventId

    let pickPromise
    // if user has picks, update pick ids
    if (!userPick) {
      pickPromise = client.graphql({
        query: createPick,
        variables: {
          input: formJson
        }
      }).then(response => {
        setUserPick(response.data.createPick)
        setSubmitDisabled(false)
      }).catch(error => {
        setSubmitDisabled(false)
        console.log(error)
      })
    } else { // else user did not have picks yet, create picks with ids
      const pickChanged = userPick.mpoPopId  !== formJson.mpoPopId || 
                          userPick.mpoLockId !== formJson.mpoLockId || 
                          userPick.mpoDropId !== formJson.mpoDropId || 
                          userPick.fpoPopId  !== formJson.fpoPopId ||
                          userPick.fpoLockId !== formJson.fpoLockId ||
                          userPick.fpoDropId !== formJson.fpoDropId

      // if we have changes compare to user existing pick update the pick                      
      if (pickChanged) {
        formJson["id"] = userPick.id
        pickPromise = client.graphql({
          query: updatePick,
          variables: {
            input: formJson
          }
        }).then(response => {
          setUserPick(response.data.updatePick)
          setSubmitDisabled(false)
        }).catch(error => {
          setSubmitDisabled(false)
          console.log(error)
        })
      } else {
        console.log("skipping pick update since there were no changes to the user's already existing picks")
        toast("Saved picks")
        setSubmitDisabled(false)
      }
    }

    if (pickPromise) {
      toast.promise(pickPromise, {
        pending: 'Saving picks',
        success: 'Saved picks',
        error: 'Failed to save picks'
      })

      await pickPromise
      updateSavedPicks()
    }
  }

  async function updateSavedPicks() {
    const startOfYear = new Date(Date.UTC(selectedYear, 0, 1, 0, 0, 0)).toISOString();  // January 1st, 00:00 UTC

    const userLockPicks = await client.graphql({
      query: getPicksByUserID,
      variables: {
        userId: dynoUser.id,
        startDate: startOfYear 
      }
    })

    const savedPicks = userLockPicks.data.pickByUser.items
    let pickMap = {}
    savedPicks.forEach(pick => {
      if (pick.fpoLockId in pickMap) {
        pickMap[pick.fpoLockId] += 1
      } else {
        pickMap[pick.fpoLockId] = 1
      }

      if (pick.mpoLockId in pickMap) {
        pickMap[pick.mpoLockId] += 1
      } else {
        pickMap[pick.mpoLockId] = 1
      }
    })

    // query to get the picks for this user so the remaining picks field updates
    setSavedUserPicks(pickMap)
  }

  // Load player options
  const eventSelected = async (eventId) => {
    setTheEvent(eventId)

    setMpoPlayerOptions()
    setMpoPopDropPlayerOptions()
    setFpoPlayerOptions()
    setFpoPopDropPlayerOptions()

    setUserPick(null)
    setMpoPopPick(-1)
    setMpoLockPick(-1)
    setMpoDropPick(-1)
    setFpoPopPick(-1)
    setFpoLockPick(-1)
    setFpoDropPick(-1)

    if (eventId === '-1')  {
      setSubmitDisabled(false)

      return;
    }

    let useRating = false
    eventOptions.forEach(eventObj => {
      if (eventId === eventObj["id"]) {
        useRating = eventObj.useRating
      }
    })

    if (useRating === undefined || useRating === null) {
      useRating = false
    }

    setUseRating(useRating)

    const eventPlayers = await client.graphql({
      query: listPlayersForEvent,
      variables: {
        id: eventId
      }
    });

    // query to get the picks for this user so the remaining picks field updates
    updateSavedPicks()

    const date = new Date().toISOString();
    setSubmitDisabled(eventPlayers.data.getEvent.startDate < date)

    const playerOptions = eventPlayers.data.getEvent.players.items
    let mpoPlayers = [];
    let fpoPlayers = [];
    let mpoPopDropPlayers = [];
    let fpoPopDropPlayers = [];

    playerOptions.forEach(({player}) => {
      if (player.currentAverage === null || player.currentAverage === -1) {
        player.currentAverage = -1;
      }

      if (player.division === 'MPO') {
        if (player.currentAverage != -1 || useRating) {
          mpoPopDropPlayers.push(player)
        }

        mpoPlayers.push(player);
      } else if (player.division === 'FPO') {
        if (player.currentAverage != -1 || useRating) {
          fpoPopDropPlayers.push(player)
        }

        fpoPlayers.push(player);
      } else {
        console.log('Invalid player division:', player.name, player.division);
      }
    })

    mpoPlayers.sort((a, b) => {
      return a.name.localeCompare(b.name);
    })
    setMpoPlayerOptions(mpoPlayers);

    mpoPopDropPlayers.sort((a, b) => {
      return a.name.localeCompare(b.name);
    })
    setMpoPopDropPlayerOptions(mpoPopDropPlayers);

    fpoPlayers.sort((a, b) => {
      return a.name.localeCompare(b.name);
    })
    setFpoPlayerOptions(fpoPlayers);

    fpoPopDropPlayers.sort((a, b) => {
      return a.name.localeCompare(b.name);
    })
    setFpoPopDropPlayerOptions(fpoPopDropPlayers);

    // set selections if user already has picks
    client.graphql({
      query: pickByUserEvent,
      variables: {
        userEventId: dynoUser.id + "." + eventId
      }
    }).then(response => {
      if (response.data.pickByUserEvent.items.length === 0) {
        setUserPick(null)
        setMpoPopPick(-1)      
        setMpoLockPick(-1)
        setMpoDropPick(-1)
        setFpoPopPick(-1)      
        setFpoLockPick(-1)
        setFpoDropPick(-1)  
      } else {
        let pick = response.data.pickByUserEvent.items[0]
        setUserPick(pick)
        setMpoPopPick(pick["mpoPopId"])      
        setMpoLockPick(pick["mpoLockId"])
        setMpoDropPick(pick["mpoDropId"])
        setFpoPopPick(pick["fpoPopId"])      
        setFpoLockPick(pick["fpoLockId"])
        setFpoDropPick(pick["fpoDropId"])  
      }
    }).catch( error => [
      console.log(error)
    ])

    setLoading(true);
    setUserScore(undefined);
    client.graphql({
      query: getPicksByEventID,
      variables: {
        eventId: eventId
      }
    }).then(response => {
      let picks = response.data.pickByEvent.items;
      let i = 1;
      let foundPick = false;
      for (const pick of picks) {
        if (pick.user.username === username) {
          pick["place"] = i;
          setUserScore(pick);
          foundPick = true;
          break;
        } 

        i++;
      }

      if (!foundPick) {
        let dummyPick = {
          place: "N/A",
          total_score: "N/A"
        }
        setUserScore(dummyPick)
      }

      setLoading(false);
      setPicksForEvent(picks)
    }).catch( error => {
      console.log(error)
      setLoading(false);
    })
  }

  return (
      <>
        <TopNavBar username={username} selectedYear={selectedYear} handleYearChange={handleYearChange}/>
        <Container fluid className="pt-3">
          <Tabs
            defaultActiveKey="picks"
            id="tab-controller"
            className="mb-3"
          >
            <Tab eventKey="picks" title="Picks">
              <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover={false}
                theme="dark"
                transition={Slide}
              />
              <Form onSubmit={handleSubmit}>
                <Row className={'pb-3'}>
                  <Col xs={12} md={4}>
                    <Form.Label>Event</Form.Label>
                    <Form.Select name="eventId" onChange={(e) => {
                      const eventId = e.target.value; // Extract the event ID
                      const chosenEvent = eventOptions.find(event => event.id === eventId);
                      
                      if (chosenEvent) {
                        eventSelected(chosenEvent.id);
                      }
                    }} value={theEvent}>
                      <option value={-1}>Select an Event</option>
                      {eventOptions?.map((event, idx) => {
                        return (<option key={`event_${idx}`}
                                        value={event.id}>{event.name}</option>)
                      })}
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col>
                    <PlayerPicker label="MPO Pop"
                                  options={mpoPopDropPlayerOptions}
                                  name="mpoPopId"
                                  value={mpoPopPick}
                                  useRating= {useRating}
                                  onChange={handlePickChange}></PlayerPicker>
                  </Col>
                  <Col>
                    <PlayerPicker label="MPO Drop"
                                  options={mpoPopDropPlayerOptions}
                                  name="mpoDropId"
                                  value={mpoDropPick}
                                  useRating= {useRating}
                                  onChange={handlePickChange}></PlayerPicker>
                  </Col>
                  <Col>
                    <PlayerPicker label="MPO Lock"
                                  options={mpoPlayerOptions}
                                  name="mpoLockId"
                                  value={mpoLockPick}
                                  useRating= {useRating}
                                  savedUserPicks={savedUserPicks}
                                  onChange={handlePickChange}></PlayerPicker>
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col>
                    <PlayerPicker label="FPO Pop"
                                  options={fpoPopDropPlayerOptions}
                                  name="fpoPopId"
                                  value={fpoPopPick}
                                  useRating= {useRating}
                                  onChange={handlePickChange}></PlayerPicker>
                  </Col>
                  <Col>
                    <PlayerPicker label="FPO Drop"
                                  options={fpoPopDropPlayerOptions}
                                  name="fpoDropId"
                                  value={fpoDropPick}
                                  useRating= {useRating}
                                  onChange={handlePickChange}></PlayerPicker>
                  </Col>
                  <Col>
                    <PlayerPicker label="FPO Lock"
                                  options={fpoPlayerOptions}
                                  name="fpoLockId"
                                  value={fpoLockPick}
                                  useRating= {useRating}
                                  savedUserPicks={savedUserPicks}
                                  onChange={handlePickChange}></PlayerPicker>
                  </Col>
                </Row>
                <div sm={1} className="text-center">
                  <Button type="submit" disabled={submitDisabled}>Submit</Button>
                </div>
              </Form>
              <Row>
                <Col>
                  <Image src="Play-Along-With-SS.png" className="center" width="350"></Image>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="leaderboard" title="Leaderboard">
              <Row className={'pb-3'}>
                <Col xs={12} md={4}>                
                  <Form.Label>Event</Form.Label>
                  <Form.Select name="eventId" onChange={(e) => {
                    const eventId = e.target.value; // Extract the event ID
                    const chosenEvent = eventOptions.find(event => event.id === eventId);
                    
                    if (chosenEvent) {
                      eventSelected(chosenEvent.id);
                    }
                  }} value={theEvent}>
                    <option value={-1}>Select an Event</option>
                    {eventOptions?.map((event, idx) => {
                      return (<option key={`event_${idx}`}
                                      value={event.id}>{event.name}</option>)
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row className={'pb-3'}>
                <TailSpin
                  visible={loading}
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  />
              </Row>
              {userScore && <Row className={'pb-3'}>
                <span>Your Place: {(userScore.place !== "N/A") ? userScore.place + " out of " + picksForEvent.length : userScore.place}</span>
                <span>Your Score: {userScore.total_score}</span>
              </Row>}
              <Row className={'pb-3'} >
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Username</th>
                      <th>Score</th>
                      <th>MPO Pop</th>
                      <th>MPO Pop Score</th>
                      <th>MPO Drop</th>
                      <th>MPO Drop Score</th>
                      <th>MPO Lock</th>
                      <th>MPO Lock Score</th>
                      <th>FPO Pop</th>
                      <th>FPO Pop Score</th>
                      <th>FPO Drop</th>
                      <th>FPO Drop Score</th>
                      <th>FPO Lock</th>
                      <th>FPO Lock Score</th>
                      <th>SS Host</th>
                    </tr>
                  </thead>
                  <tbody>
                    {picksForEvent?.map((pick, idx) => {
                      return (
                        <tr key={`pick_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{pick.user.username}</td>
                          <td>{pick.total_score}</td>
                          <td>{(pick.mpoPop) ? pick.mpoPop.name : "N/A" }</td>
                          <td>{pick.mpoPopScore}</td>
                          <td>{(pick.mpoDrop) ? pick.mpoDrop.name : "N/A" }</td>
                          <td>{pick.mpoDropScore}</td>
                          <td>{(pick.mpoLock) ? pick.mpoLock.name : "N/A" }</td>
                          <td>{pick.mpoLockScore}</td>
                          <td>{(pick.fpoPop) ? pick.fpoPop.name : "N/A" }</td>
                          <td>{pick.fpoPopScore}</td>
                          <td>{(pick.fpoDrop) ? pick.fpoDrop.name : "N/A" }</td>
                          <td>{pick.fpoDropScore}</td>
                          <td>{(pick.fpoLock) ? pick.fpoLock.name : "N/A" }</td>
                          <td>{pick.fpoLockScore}</td>
                          <td>{pick.user.persona}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </>
  )
}

export default Home;
