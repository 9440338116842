/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPlayer = /* GraphQL */ `
  mutation CreatePlayer(
    $input: CreatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    createPlayer(input: $input, condition: $condition) {
      id
      name
      division
      events {
        nextToken
        __typename
      }
      pdgaNumber
      currentAverage
      average2023
      average2024
      average2025
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlayer = /* GraphQL */ `
  mutation UpdatePlayer(
    $input: UpdatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    updatePlayer(input: $input, condition: $condition) {
      id
      name
      division
      events {
        nextToken
        __typename
      }
      pdgaNumber
      currentAverage
      average2023
      average2024
      average2025
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlayer = /* GraphQL */ `
  mutation DeletePlayer(
    $input: DeletePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    deletePlayer(input: $input, condition: $condition) {
      id
      name
      division
      events {
        nextToken
        __typename
      }
      pdgaNumber
      currentAverage
      average2023
      average2024
      average2025
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPick = /* GraphQL */ `
  mutation CreatePick(
    $input: CreatePickInput!
    $condition: ModelPickConditionInput
  ) {
    createPick(input: $input, condition: $condition) {
      id
      userEventId
      userId
      eventId
      mpoPopId
      mpoLockId
      mpoDropId
      fpoPopId
      fpoLockId
      fpoDropId
      mpoPopScore
      mpoLockScore
      mpoDropScore
      fpoPopScore
      fpoLockScore
      fpoDropScore
      total_score
      mpoPop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      mpoLock {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      mpoDrop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      fpoPop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      fpoLock {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      fpoDrop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        email
        username
        owner
        persona
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        name
        startDate
        endDate
        pdgaUrl
        dgsUrl
        scoreUrl
        useRating
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePick = /* GraphQL */ `
  mutation UpdatePick(
    $input: UpdatePickInput!
    $condition: ModelPickConditionInput
  ) {
    updatePick(input: $input, condition: $condition) {
      id
      userEventId
      userId
      eventId
      mpoPopId
      mpoLockId
      mpoDropId
      fpoPopId
      fpoLockId
      fpoDropId
      mpoPopScore
      mpoLockScore
      mpoDropScore
      fpoPopScore
      fpoLockScore
      fpoDropScore
      total_score
      mpoPop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      mpoLock {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      mpoDrop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      fpoPop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      fpoLock {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      fpoDrop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        email
        username
        owner
        persona
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        name
        startDate
        endDate
        pdgaUrl
        dgsUrl
        scoreUrl
        useRating
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePick = /* GraphQL */ `
  mutation DeletePick(
    $input: DeletePickInput!
    $condition: ModelPickConditionInput
  ) {
    deletePick(input: $input, condition: $condition) {
      id
      userEventId
      userId
      eventId
      mpoPopId
      mpoLockId
      mpoDropId
      fpoPopId
      fpoLockId
      fpoDropId
      mpoPopScore
      mpoLockScore
      mpoDropScore
      fpoPopScore
      fpoLockScore
      fpoDropScore
      total_score
      mpoPop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      mpoLock {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      mpoDrop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      fpoPop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      fpoLock {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      fpoDrop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        email
        username
        owner
        persona
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        name
        startDate
        endDate
        pdgaUrl
        dgsUrl
        scoreUrl
        useRating
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      players {
        nextToken
        __typename
      }
      pdgaUrl
      dgsUrl
      scoreUrl
      useRating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      players {
        nextToken
        __typename
      }
      pdgaUrl
      dgsUrl
      scoreUrl
      useRating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      players {
        nextToken
        __typename
      }
      pdgaUrl
      dgsUrl
      scoreUrl
      useRating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      username
      owner
      persona
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      username
      owner
      persona
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      username
      owner
      persona
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventPlayers = /* GraphQL */ `
  mutation CreateEventPlayers(
    $input: CreateEventPlayersInput!
    $condition: ModelEventPlayersConditionInput
  ) {
    createEventPlayers(input: $input, condition: $condition) {
      id
      playerId
      eventId
      player {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        name
        startDate
        endDate
        pdgaUrl
        dgsUrl
        scoreUrl
        useRating
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventPlayers = /* GraphQL */ `
  mutation UpdateEventPlayers(
    $input: UpdateEventPlayersInput!
    $condition: ModelEventPlayersConditionInput
  ) {
    updateEventPlayers(input: $input, condition: $condition) {
      id
      playerId
      eventId
      player {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        name
        startDate
        endDate
        pdgaUrl
        dgsUrl
        scoreUrl
        useRating
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventPlayers = /* GraphQL */ `
  mutation DeleteEventPlayers(
    $input: DeleteEventPlayersInput!
    $condition: ModelEventPlayersConditionInput
  ) {
    deleteEventPlayers(input: $input, condition: $condition) {
      id
      playerId
      eventId
      player {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        name
        startDate
        endDate
        pdgaUrl
        dgsUrl
        scoreUrl
        useRating
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const customCreateUser = /* GraphQL */ `
  mutation CustomCreateUser {
    customCreateUser
  }
`;
