
import {Button, Container, Navbar, Image, Modal, Dropdown} from "react-bootstrap";
import {useAuthenticator} from "@aws-amplify/ui-react";
import { useState } from 'react';

function TopNavBar(props) {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const { username, selectedYear, handleYearChange } = props;
  const [show, setShow] = useState(false);

  const handleRulesOnClick = (event) => {
    setShow(true)
  }
  
  const handleClose = () => setShow(false);

  return (
    <Navbar expand="lg" sticky="top" bg="primary" data-bs-theme="dark">
      <Container fluid>
        <Navbar.Brand>
          <img
            src="PDL-Logo-basket.png"
            width="100"
            height="57"
          />
        </Navbar.Brand>
        <Image src="PresentedBy-Hooligan.png" width="100" height="56"></Image>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className="pe-3">
            Signed in as: {username}
          </Navbar.Text>
          <div className="d-flex align-items-center">
            <Dropdown onSelect={handleYearChange}>
            <Dropdown.Toggle className="no-hover-dropdown me-2" id="dropdown-year">
                {selectedYear}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey={2024}>2024</Dropdown.Item>
                <Dropdown.Item eventKey={2025}>2025</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button onClick={handleRulesOnClick} className="me-2">Rules</Button>
            <Button onClick={() => signOut()} className="me-2">Sign Out</Button>
          </div>
        </Navbar.Collapse>
      </Container>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Pop, Drop, Lock Rules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div><p><strong>Overview:</strong></p>
          <p>"Pop Drop Lock" is an engaging and strategic game provided by Staggered Stance, where participants test their predictive skills while following the Disc Golf Pro Tour. Participants select three players from the Mixed Pro Open (MPO) division and three players from the Female Pro Open (FPO) division, assigning each player to one of three categories: "Pop," "Drop," or "Lock.". This takes place before each tournament stop on the DGPT Elite Series and at PDGA Majors.</p>
          <p><strong>Objective:</strong></p>
          <p>The goal of "Pop Drop Lock" is to maximize positive points in each category: "Pop," "Drop," and "Lock." Participants aim to select players who outperform their average tour finishes ("Pop"), accurately predict players who finish below their average performances ("Drop"), and secure top finishers or a winner ("Lock"). By making informed choices based on players' past performances and tournament expectations, participants aim to maximize positive points while engaging with the excitement of the Disc Golf Pro Tour.</p>
          <p><strong>Game Rules:</strong></p>
          <p>Participants must visit www.popdroplock.com to create an account and submit their selections before 7 am Eastern Standard Time on the day of the tournament.</p>
          <p>Each participant selects three players from the MPO division and three players from the FPO division.</p>
          <p>Participants assign each selected player to one of the categories: "Pop," "Drop," or "Lock."</p>
          <p>Selected players must have participated in at least three tour events to ensure sufficient data for accurate average results.The exception to this is if a participant selects a player who has played three or less events as their "Lock" pick. This is because a "Lock" pick does not rely on average tour finishes. Simply put, any player can be chosen to be a "Lock" pick. Participants "Pop" or "Drop" picks simply have to have at least three tour events completed.</p>
          <p>Participants cannot select the same player as their "Lock" pick more than five times in a given season.</p>
          <p><strong>Points System:</strong></p>
          <p><strong>Pop:</strong> Participants earn positive points for each place a player chosen for "Pop" finishes above their tour average. Conversely, if the player finishes below their tour average, the participant receives negative points for each place below their tour average. If the player does not finish the tournament (DNF), the participant does not receive any points, positive or negative.</p>
          <p><strong>Drop</strong>: Participants earn one point for each place a player chosen for "Drop" finishes below their tour average. Conversely, if the player finishes above their tour average, the participant loses one point for each place above their tour average. If the player does not finish the tournament (DNF), the participant does not receive any points, positive or negative.</p>
          <p><strong>Lock</strong>: Participants earn points based on the performance of the player picked.</p>
          <p>o   Win: 25 points</p>
          <p>o   Top five finish: 10 points</p>
          <p>o   Sixth to tenth place finish: 5 points</p>  
          <p><strong>Prize:</strong></p>
          <p>A prize will be awarded to the participant with the most points combined from all of their chosen players in each category, adding an extra level of excitement and competition to the game. In the case of a tie, the participant who submitted their picks first will be awarded the prize.</p>
          <p><strong>Conclusion:</strong></p>
          <p>"Pop Drop Lock," provided by Staggered Stance, is an exciting game where participants predict players' performances on the Disc Golf Pro Tour. With clear rules and the chance to win prizes, it's a fun way for disc golf fans to engage with the sport.</p>
          <p><strong>FAQ:</strong></p>
          <p><strong>1. How are the players' tour averages calculated?</strong></p>
          <p><em>The players' tour averages are calculated using the results of Disc Golf Pro Tour Elite tournaments or PDGA Major Tournaments. For the first three Pop Drop Lock picks, including picks for Prodigy Presents WACO and The Open at Austin, averages from the 2023 season are used. After these events, averages from the 2024 season are used. Participants can view data for a players average finish on StatMando.com. Points are not updated to this game platform during the tournament but are calculated and posted the following morning after the tournament concludes.</em></p>
          <p><strong>2. Can I change my player selections after submitting them?</strong></p>
          <p><em>Yes, you can change your player selections after submitting them up until 7 am Eastern Standard Time on the day of the tournament start.</em></p>
          <p><strong>3. Are there any restrictions on which players I can choose for each category?</strong></p>
          <p><em>Participants cannot select the same player as their "Lock" more than five times in a given season.</em></p>
          <p><strong>4. How will I know if my chosen players have finished above or below their tour averages?</strong></p>
          <p><em>Results will not be updated during the tournament. Instead, they will be posted the following morning after the tournament concludes. In this first phase of development you will have to manually see how your players performed compared to their average tour finish. The game platform will simply calculate your total points earned for your picks.</em></p>
          <p><strong>5. Will there be updates or notifications during the tournament to track my players' performances?</strong></p>
          <p><em>No updates or notifications will be provided during the tournament. Results will be posted the following morning after the tournament concludes. We would like to include future updates that show more information during the live event, but this will not happen in phase one of development.</em></p>
          <p><strong>6. Is there a limit to the number of participants in the game?</strong></p>
          <p><em>There is no specific limit to the number of participants in the game. Anyone can join and participate if they adhere to the game rules and deadlines.</em></p>
          <p><strong>7. How are tiebreakers handled if multiple participants have the same total points?</strong></p>
          <p><em>In the event of a tie after the tournament concludes, tiebreakers will be determined based on the timing of submission of player selections. Participants who submitted their picks first will be ranked higher in the event of a tie.</em></p>
          <p><strong>8. Can I join the game after the season has already started?</strong></p>
          <p><em>Yes, you can join the game at any point in the season because points reset each week. This ensures a clean slate for all participants, allowing new players to join and compete on equal footing with existing participants.</em></p>
          <p><strong>9. What happens if a tournament is canceled or postponed?</strong></p>
          <p><em>If a tournament is canceled or postponed, participants should stay tuned for more information. Updates and announcements regarding any adjustments to the game schedule or rules will be provided by Staggered Stance. If a tournament is canceled after the start of the event and with enough holes/rounds to officially be rated, the final results will be used to calculate finishes for players.</em></p>
          <p><strong>10. Are there any additional prizes or rewards for specific achievements within the game?</strong></p>
          <p><em>Yes, winners will be contacted by the email that they used to create the account. Staggered Stance will request a full name and shipping address from the winners so that a prize can be provided.</em></p>
          <p><strong>11. Who do I contact to report an issue or make a suggestion?</strong></p>
          <p><em>Send Dave an email at schreckdiscgolf@gmail.com</em></p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Navbar>   
  )
}

export default TopNavBar;
