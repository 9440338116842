/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlayer = /* GraphQL */ `
  query GetPlayer($id: ID!) {
    getPlayer(id: $id) {
      id
      name
      division
      events {
        nextToken
        __typename
      }
      pdgaNumber
      currentAverage
      average2023
      average2024
      average2025
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlayers = /* GraphQL */ `
  query ListPlayers(
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const playerByName = /* GraphQL */ `
  query PlayerByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    playerByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const playerByPdgaNumber = /* GraphQL */ `
  query PlayerByPdgaNumber(
    $pdgaNumber: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    playerByPdgaNumber(
      pdgaNumber: $pdgaNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPick = /* GraphQL */ `
  query GetPick($id: ID!) {
    getPick(id: $id) {
      id
      userEventId
      userId
      eventId
      mpoPopId
      mpoLockId
      mpoDropId
      fpoPopId
      fpoLockId
      fpoDropId
      mpoPopScore
      mpoLockScore
      mpoDropScore
      fpoPopScore
      fpoLockScore
      fpoDropScore
      total_score
      mpoPop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      mpoLock {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      mpoDrop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      fpoPop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      fpoLock {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      fpoDrop {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        email
        username
        owner
        persona
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        name
        startDate
        endDate
        pdgaUrl
        dgsUrl
        scoreUrl
        useRating
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPicks = /* GraphQL */ `
  query ListPicks(
    $filter: ModelPickFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPicks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userEventId
        userId
        eventId
        mpoPopId
        mpoLockId
        mpoDropId
        fpoPopId
        fpoLockId
        fpoDropId
        mpoPopScore
        mpoLockScore
        mpoDropScore
        fpoPopScore
        fpoLockScore
        fpoDropScore
        total_score
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const pickByUserEvent = /* GraphQL */ `
  query PickByUserEvent(
    $userEventId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPickFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pickByUserEvent(
      userEventId: $userEventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userEventId
        userId
        eventId
        mpoPopId
        mpoLockId
        mpoDropId
        fpoPopId
        fpoLockId
        fpoDropId
        mpoPopScore
        mpoLockScore
        mpoDropScore
        fpoPopScore
        fpoLockScore
        fpoDropScore
        total_score
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const pickByUser = /* GraphQL */ `
  query PickByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPickFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pickByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userEventId
        userId
        eventId
        mpoPopId
        mpoLockId
        mpoDropId
        fpoPopId
        fpoLockId
        fpoDropId
        mpoPopScore
        mpoLockScore
        mpoDropScore
        fpoPopScore
        fpoLockScore
        fpoDropScore
        total_score
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const pickByEvent = /* GraphQL */ `
  query PickByEvent(
    $eventId: ID!
    $total_score: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPickFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pickByEvent(
      eventId: $eventId
      total_score: $total_score
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userEventId
        userId
        eventId
        mpoPopId
        mpoLockId
        mpoDropId
        fpoPopId
        fpoLockId
        fpoDropId
        mpoPopScore
        mpoLockScore
        mpoDropScore
        fpoPopScore
        fpoLockScore
        fpoDropScore
        total_score
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      startDate
      endDate
      players {
        nextToken
        __typename
      }
      pdgaUrl
      dgsUrl
      scoreUrl
      useRating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        startDate
        endDate
        pdgaUrl
        dgsUrl
        scoreUrl
        useRating
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      username
      owner
      persona
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        username
        owner
        persona
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        username
        owner
        persona
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventPlayers = /* GraphQL */ `
  query GetEventPlayers($id: ID!) {
    getEventPlayers(id: $id) {
      id
      playerId
      eventId
      player {
        id
        name
        division
        pdgaNumber
        currentAverage
        average2023
        average2024
        average2025
        rating
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        name
        startDate
        endDate
        pdgaUrl
        dgsUrl
        scoreUrl
        useRating
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventPlayers = /* GraphQL */ `
  query ListEventPlayers(
    $filter: ModelEventPlayersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        playerId
        eventId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventPlayersByPlayerId = /* GraphQL */ `
  query EventPlayersByPlayerId(
    $playerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventPlayersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventPlayersByPlayerId(
      playerId: $playerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        playerId
        eventId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventPlayersByEventId = /* GraphQL */ `
  query EventPlayersByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventPlayersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventPlayersByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        playerId
        eventId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
