export const listEventOptions = /* GraphQL */ `
  query ListEventNames(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        startDate
        useRating
      }
      nextToken
      __typename
    }
  }
`;

export const listEventOptionsForYear = `
  query ListEventsByYear($startOfYear: String!, $endOfYear: String!) {
    listEvents(
      filter: {
        startDate: {
          between: [
            $startOfYear,
            $endOfYear
          ]
        }
      }
    ) {
      items {
        id
        name
        startDate
        endDate
        pdgaUrl
        dgsUrl
        scoreUrl
        useRating
      }
    }
  }
`

export const listPlayersForEvent = /* GraphQL */ `
  query listPlayersForEvent($id: ID!) {
    getEvent(id: $id) {
      startDate
      players {
        items {
          player {
            id
            name
            division
            currentAverage
            rating
          }
        }
      }
    }
  }
`;

export const getUserPicksByEventID = /* GraphQL */ `
  query GetUserPicksByEventID($id: ID!) {
    getUser(id: $id) {
      id
      comboID
      users {
        nextToken
        __typename
      }
      eventID
      playerID
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const getPicksByEventID = /* GraphQL */ `
  query GetPicksByEventID($eventId: ID!) {
    pickByEvent(eventId: $eventId, sortDirection: DESC, limit: 600) {
      items {
        fpoDrop {
          name
        }
        fpoLock {
          name
        }
        fpoPop {
          name
        }
        mpoDrop {
          name
        }
        mpoLock {
          name
        }
        mpoPop {
          name
        }
        fpoLockScore
        fpoDropScore
        fpoPopScore
        mpoDropScore
        mpoLockScore
        mpoPopScore
        total_score
        user {
          username
          persona
        }
      }
    }
  }
`;

export const getPicksByUserID = /* GraphQL */ `
  query GetPicksByUserID($userId: ID!, $startDate: String!) {
    pickByUser(userId: $userId, filter: { createdAt: { gt: $startDate } }) {
      items {
        fpoLockId
        mpoLockId
        event {
          id
          name
          startDate
        }
      }
    }
  }
`;